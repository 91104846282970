export var riskReasonImage = [{
  "label": "pornographic_adultContent",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有成人色情内容。"
}, {
  "label": "pornographic_adultContent_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有色情内容。"
}, {
  "label": "sexual_suggestiveContent",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有疑似低俗或性暗示内容。"
}, {
  "label": "sexual_partialNudity",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有包含肢体裸露或性感内容。"
}, {
  "label": "political_politicalFigure",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有政治人物的内容。"
}, {
  "label": "political_politicalFigure_name_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有领导人姓名。"
}, {
  "label": "political_politicalFigure_metaphor_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有对主要领导人的代称、暗喻。"
}, {
  "label": "political_TVLogo",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有特定电视台台标。"
}, {
  "label": "political_map",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有中国地图。"
}, {
  "label": "political_outfit",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有公务服装。"
}, {
  "label": "political_prohibitedPerson",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有不宜宣传的人物的内容。"
}, {
  "label": "political_prohibitedPerson_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有落马官员的姓名。"
}, {
  "label": "political_taintedCelebrity",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有重大负面的公众人物的内容。"
}, {
  "label": "political_taintedCelebrity_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有劣迹艺人的姓名。"
}, {
  "label": "political_flag",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有国家或地区旗帜。"
}, {
  "label": "political_historicalNihility",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有历史虚无内容。"
}, {
  "label": "political_historicalNihility_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有历史虚无信息。"
}, {
  "label": "political_religion_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有宗教元素或信息。"
}, {
  "label": "political_racism_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有歧视的表达内容。"
}, {
  "label": "political_badge",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有国徽，党徽相关内容。"
}, {
  "label": "violent_explosion",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有烟火类内容元素。"
}, {
  "label": "violent_gunKnives",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有刀具、枪支等内容。"
}, {
  "label": "violent_gunKnives_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含枪支刀具的描述。"
}, {
  "label": "violent_armedForces",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有武装元素。"
}, {
  "label": "violent_crowding",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有人群聚集元素。"
}, {
  "label": "violent_horrificContent",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有惊悚、血腥等内容。"
}, {
  "label": "violent_horrific_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似描述暴力、恐怖的内容。"
}, {
  "label": "contraband_drug",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "含有疑似毒品等内容。"
}, {
  "label": "contraband_drug_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似描述违禁毒品。"
}, {
  "label": "contraband_gamble",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "含有疑似赌博等内容。"
}, {
  "label": "contraband_gamble_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似描述赌博行为。"
}, {
  "label": "fraud_videoAbuse",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图片疑似有隐藏视频风险。"
}, {
  "label": "fraud_playerAbuse",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图片疑似有隐藏播放器风险。"
}];
export var riskReasonVideo = [{
  "label": "pornographic_adultContent",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有成人色情内容。"
}, {
  "label": "pornographic_adultToys",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面中疑似含有成人器具内容。"
}, {
  "label": "pornographic_artwork",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面中疑似含有艺术品色情内容。"
}, {
  "label": "pornographic_adultContent_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图片中文字疑似色情内容。"
}, {
  "label": "sexual_suggestiveContent",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似低俗或性暗示内容。"
}, {
  "label": "sexual_breastBump",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有凸点轮廓内容。"
}, {
  "label": "sexual_cleavage",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有女性乳沟特征。"
}, {
  "label": "sexual_femaleUnderwear",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有内衣泳衣内容。"
}, {
  "label": "sexual_femaleShoulder",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有肩部性感内容。"
}, {
  "label": "sexual_femaleLeg",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有腿部性感内容。"
}, {
  "label": "sexual_maleTopless",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有男性赤膊内容。"
}, {
  "label": "sexual_cartoon",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有卡通性感内容。"
}, {
  "label": "sexual_pregnancy",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有孕照哺乳内容。"
}, {
  "label": "sexual_underage",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有儿童性感内容。"
}, {
  "label": "political_historicalNihility",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似涉及虚无历史或不宜传播的历史事件。"
}, {
  "label": "political_historicalNihility_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似历史虚无。"
}, {
  "label": "political_politicalFigure_1",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含现任历任领导人。"
}, {
  "label": "political_politicalFigure_2",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似涉及领导人家属。"
}, {
  "label": "political_politicalFigure_3",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似涉及省、市政府人员。"
}, {
  "label": "political_politicalFigure_4",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似涉及国外领导人及家属。"
}, {
  "label": "political_politicalFigure_name_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字含领导人姓名。"
}, {
  "label": "political_politicalFigure_metaphor_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有对主要领导人的代称、暗喻。"
}, {
  "label": "political_prohibitedPerson_1",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有国家级落马官员。"
}, {
  "label": "political_prohibitedPerson_2",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有省市级落马官员。"
}, {
  "label": "political_prohibitedPerson_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字含落马官员的姓名"
}, {
  "label": "political_taintedCelebrity",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含劣迹或重大负面的公众人物。"
}, {
  "label": "political_taintedCelebrity_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似有劣迹艺人的姓名。"
}, {
  "label": "political_Chinaflag",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有中国国旗。"
}, {
  "label": "political_otherflag",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有其他国家国旗。"
}, {
  "label": "political_Chinamap",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有中国地图。"
}, {
  "label": "political_logo",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有禁宣媒体标识。"
}, {
  "label": "political_outfit",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有军警服装、作战部队服装。"
}, {
  "label": "political_badge",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有国徽、党徽。"
}, {
  "label": "political_racism_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有特殊的表达。"
}, {
  "label": "violent_explosion",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有烟火类内容元素。"
}, {
  "label": "violent_armedForces",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含暴恐组织。"
}, {
  "label": "violent_crowding",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似有人群聚集。"
}, {
  "label": "violent_gun",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含枪支。"
}, {
  "label": "violent_Knives",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含刀具。"
}, {
  "label": "violent_gunKnives_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字含枪支刀具的描述。"
}, {
  "label": "violent_blood",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有血腥内容。"
}, {
  "label": "violent_horrific",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含惊悚内容。"
}, {
  "label": "violent_horrific_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似描述暴力、恐怖的内容。"
}, {
  "label": "violent_ACU",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含作战服。"
}, {
  "label": "contraband_drug",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似毒品、药品。"
}, {
  "label": "contraband_drug_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似描述违禁毒品。"
}, {
  "label": "contraband_gamble",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似赌博物品。"
}, {
  "label": "contraband_gamble_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似描述赌博行为。"
}, {
  "label": "contraband_certificate_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含办证套现类广告引流。"
}, {
  "label": "religion_funeral",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似含有葬礼灵堂内容。"
}, {
  "label": "religion_buddhism",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "疑似含有特定服饰或标识。"
}, {
  "label": "religion_christianity",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "religion_muslim",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "religion_tii",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "racism_tii",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "PDA_kiss",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含亲吻内容。"
}, {
  "label": "PDA_physicalContact",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含亲密行为。"
}, {
  "label": "object_landmark",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含国内地标内容。"
}, {
  "label": "object_rmb",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含人民币、硬币。"
}, {
  "label": "object_wn",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含特定形象。"
}, {
  "label": "pt_logotoSocialNetwork",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面中含有常见网络社交平台水印。"
}, {
  "label": "pt_qrCode",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中包含二维码。"
}, {
  "label": "pt_programCode",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中包含小程序码。"
}, {
  "label": "pt_toDirectContact_tii",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图中文字疑似含有特定引流信息。"
}, {
  "label": "pt_toSocialNetwork_tii",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "pt_toShortVideos_tii",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "pt_investment_tii",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "pt_recruitment_tii",
  "confidence": "0~100分，分数越高置信度越高"
}, {
  "label": "inappropriate_smoking",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含烟相关内容。"
}, {
  "label": "inappropriate_drinking",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含酒相关内容。"
}, {
  "label": "inappropriate_tattoo",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含纹身内容。"
}, {
  "label": "inappropriate_middleFinger",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含竖中指内容。"
}, {
  "label": "inappropriate_foodWasting",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "画面疑似包含浪费粮食内容。"
}, {
  "label": "quality_meaningless",
  "confidence": "0~100分，分数越高置信度越高",
  "desc": "图片元素过少。"
}];