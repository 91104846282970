//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'mapPicker',
  props: {
    lnglat: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      mapStatus: false,
      mapUrl: 'https://usemap.ulthon.com/src/picker.html',
      callbackKey: null
    };
  },
  watch: {
    mapStatus: function mapStatus(val) {
      if (!val) {
        this.onCloseMap();
      }
    }
  },
  methods: {
    onOpenMap: function onOpenMap() {
      var _this = this;
      this.callbackKey = 'mapPickerCallback' + new Date().getTime();
      this.mapStatus = true;
      window[this.callbackKey] = function (env) {
        var result = env.data;
        if (result.callbackKey != _this.callbackKey) return;
        if (result.type == 'cancel') {
          _this.mapStatus = false;
        } else if (result.type == 'confirm') {
          _this.mapStatus = false;
          _this.$emit('onConfirm', result.data);
        }
      };
      window.addEventListener('message', window[this.callbackKey], false);
    },
    onCloseMap: function onCloseMap() {
      window.removeEventListener('message', window[this.callbackKey]);
      delete window[this.callbackKey];
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.onCloseMap();
  }
};