var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "acea-row" },
        _vm._l(_vm.imageList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "pictrue",
              attrs: { draggable: "false" },
              on: {
                dragstart: function ($event) {
                  return _vm.handleDragStart($event, item)
                },
                dragover: function ($event) {
                  $event.preventDefault()
                  return _vm.handleDragOver($event, item)
                },
                dragenter: function ($event) {
                  return _vm.handleDragEnter($event, item)
                },
                dragend: function ($event) {
                  return _vm.handleDragEnd($event, item)
                },
              },
            },
            [
              item.type.indexOf("image") !== -1
                ? [
                    _c("img", {
                      staticClass: "preview-item",
                      attrs: { src: item.url },
                    }),
                  ]
                : item.type.indexOf("video") !== -1
                ? [
                    _c("video", {
                      staticClass: "preview-item",
                      attrs: { controls: "", src: item.url },
                    }),
                  ]
                : [
                    _c("div", { staticClass: "file-name" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.fileName) + "\n        "
                      ),
                    ]),
                  ],
              _vm._v(" "),
              !_vm.disabled
                ? _c("i", {
                    staticClass: "el-icon-error btndel",
                    on: {
                      click: function ($event) {
                        return _vm.handleRemove(index)
                      },
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      !_vm.disabled
        ? _c("upload-file", {
            attrs: {
              multiple: _vm.multiple,
              accept: _vm.accept,
              value: _vm.newFile,
              "disable-preview": true,
            },
            on: { input: _vm.onNewFile },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }